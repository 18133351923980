#root {
  text-align: center;
  margin: 1.2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
